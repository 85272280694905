import React from "react";
import ContentBlock from "../components/Content/ContentBlock";
import PageTitle from "../components/PageTitle/PageTitle";
import Timeline from "../components/Timeline/Timeline";
import timelinedata from "../../content/timelinedata.json";

export default () => {
  return (
    <div>
      <PageTitle title="Timeline" url="http://alexdhaenens.com/timeline" />
      <ContentBlock title="Work">
        <Timeline entries={timelinedata.work} />
      </ContentBlock>
      <ContentBlock title="Studies">
        <Timeline entries={timelinedata.studies} />
      </ContentBlock>
    </div>
  );
};
