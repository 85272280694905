import React from "react";
import styled from "styled-components";
import TimelineEntry from "./TimelineEntry";

const WrapperDiv = styled.div`
  position: relative;
  padding-left: 20px;
  &:before {
    content: "";
    position: absolute;
    top: 0px;
    left: 20px;
    bottom: 0px;
    width: 1px;
    background-color: black;
  }
`;

export default ({ entries }) => {
  return (
    <WrapperDiv>
      {entries.map(e => (
        <TimelineEntry entry={e} />
      ))}
    </WrapperDiv>
  );
};
