import React from "react";
import styled from "styled-components";

const ContainerWrap = styled.div`
  margin-left: 20px;
`;

const TitleWrap = styled.h2`
  font-size: 32px;
  margin-bottom: 12px;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    border: 1px solid black;
    background-color: white;
    border-radius: 100%;
    top: 50%;
    transform: translateY(-50%);
    left: -27px;
  }
`;
export default ({ entry }) => {
  return (
    <ContainerWrap>
      <TitleWrap>
        {entry.date} {entry.title}
      </TitleWrap>
      <div>
        <p>{entry.description}</p>
      </div>
    </ContainerWrap>
  );
};
